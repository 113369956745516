@import '@one-snap/sparkles-styles/scss/core';
@import '@one-snap/sparkles-styles/scss/breakpoint-mixins';
@import '@one-snap/sparkles-styles/scss/colors';

.push-chat #be-frame {
  bottom: 75px !important;
  @include media-breakpoint-up(md) {
    bottom: 40px !important;
    right: 20px !important;
  }
}

.push-footer #footer {
  padding-bottom: 120px !important;
  @include media-breakpoint-up(md) {
    padding-bottom: 60px !important;
  }
}

